import Swiper from 'swiper/bundle';
import ScrollReveal from "scrollreveal";

export function idxFunc() {
  ScrollReveal().reveal('.anm', { delay: 500, duration: 1500 });

  //トップページ　キービジュアルスライダー
  const swiper = new Swiper('.top-slide', {
    speed: 1000,
    loop: true,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    slidesPerView: 'auto',
    spaceBetween: 0
    // breakpoints: {
    //   768: {
    //     spaceBetween: 24,  
    //   }
    // }
  });

  $('.modal-toggle').on('click', function() {
    $(this).next('.modal-content').addClass('modal-open');
    $('body').addClass('openNav');
  });

  $('.modal-content').on('click', function() {
    $(this).removeClass('modal-open');
    $('body').removeClass('openNav');
  });

  //スクロールで画像をぼかす
  const images = document.querySelectorAll('.banner-img');

  window.addEventListener('scroll', () => {
      images.forEach(image => {
          const scrollY = window.scrollY + window.innerHeight * 0.3;
          const imageBottomY = image.offsetTop + image.offsetHeight;
  
          // 画面の中心が画像の中心を超えた場合、にじみエフェクトを適用
          if (scrollY > imageBottomY) {
              image.style.filter = 'blur(5px)'; // にじみの強さを5pxに設定
          } else {
              image.style.filter = 'none';
          }
      });
  });

//   const bgElements = document.querySelectorAll('.banner-bg');

// window.addEventListener('scroll', () => {
//     bgElements.forEach(bg => {
//         const scrollY = window.scrollY + window.innerHeight * 0.75;
//         const bgBottomY = bg.offsetTop + bg.offsetHeight;

//         if (scrollY > bgBottomY) {
//             bg.style.filter = 'blur(5px)';
//         } else {
//             bg.style.filter = 'none';
//         }
//     });
// });
}