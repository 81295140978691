import objectFitImages from 'object-fit-images';
import {
    hoverFunc
} from "./common/_hoverFunc";
import {
    headerFunc
} from "./common/_header";
import {
    footerFunc
} from "./common/_footer";
import {
    pageMoveAnkerFunc
} from "./common/_pageLinkAnimation";
import {
    idxFunc
} from "./pages/_index";
import {
    idxScrollFunc
} from "./pages/_index";
//* ---------------------------------------
//通常実行
//-------------------------------------- */
let setTimer = false;
//* ----------------------------------------
//window実行
//--------------------------------------- */
////// ロード時
$(window).on('load', function () {
    idxFunc();
    hoverFunc();
    headerFunc();
    footerFunc();
    // pageMoveAnkerFunc();
    // $('#js-loader').fadeOut();
});

// リサイズ時
/*リサイズ後,200msで発火する設定*/
$(window).on('resize', function () {
    if (setTimer !== false) {
        clearTimeout(setTimer);
    }
    setTimer = setTimeout(() => {
        //処理
    }, 200);
});

// スクロール時
let offset = $('.top-sec--2col').offset().top;

var scrollBlur = {
    bannerSelector: '.banner',
    imgSelector: '.banner-img',
    degree: 2
  }
  var banner = null;
  
  // When DOM loaded
  $(function(){
    banner = $(scrollBlur.bannerSelector);
  });


$(window).on('scroll', function () {
    
    if (setTimer !== false) {
        clearTimeout(setTimer);
    }
    setTimer = setTimeout(() => {
        //処理
    }, 200);

    //背景が白っぽいところはヘッダーの色を変える
    if($(window).scrollTop() > offset) {
      $('body').addClass('is_active');
    } else {
      $('body').removeClass('is_active');
    }

    //スクロールで画像をぼかす
    // if (!banner[0]) return;
    
    // var scrollTop = $(window).scrollTop();
    
    // banner.each( function(){
    //   var offsetTop = $(this).offset().top;
      
    //   $(this).find(scrollBlur.imgSelector).each(function(){
    //     var current = $(this);
    //     if ( scrollTop > offsetTop && scrollTop < offsetTop + current.height() ) {
    //       var opacity = Math.floor( (scrollTop - offsetTop) * scrollBlur.degree / current.height() * Math.pow( 10, 2 ) ) / Math.pow( 10, 2 );
  
    //       if ( opacity <= 1 ) {
    //         current.css('opacity', opacity);
    //       }
    //     }
    //   });
    // });  
  });
///* -----------------------------------------------
//ユーザーエージェント判別実行(uaFlag：1→スマホ&タブレット・2→IE・3→その他PC)
//----------------------------------------------- */
var uaFlag = 0;
if ((
        navigator.userAgent.indexOf('iPhone') > 0) ||
    (navigator.userAgent.indexOf('iPod') > 0) ||
    (navigator.userAgent.indexOf('iPad') > 0) ||
    (navigator.userAgent.indexOf('Android') > 0) ||
    (navigator.userAgent.indexOf('BlackBerry') > 0) ||
    (navigator.userAgent.indexOf('PlayBook') > 0) ||
    (navigator.userAgent.indexOf('Kindle') > 0) ||
    (navigator.userAgent.indexOf('Silk') > 0) ||
    (navigator.userAgent.indexOf('Windows Phone') > 0) ||
    (navigator.userAgent.indexOf('Nokia') > 0)
) {
    // スマホ・タブレット時の処理
    uaFlag = 1;
} else {
    // PC時の処理
    uaFlag = 3;
}
/* -----------------------------------------------
IEだけの時
----------------------------------------------- */
var userAgent = window.navigator.userAgent.toLowerCase();
if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {

}




