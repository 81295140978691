export function headerFunc() {
  // ハンバーガーメニュー実装
  $('.navToggle').on('click', function() {
    $('.navToggle').toggleClass('closeNav');
    $('.site-header').toggleClass('openNav');
    $('.headerNav').toggleClass('openNav');
    $('body').toggleClass('openNav');
  });

  $('.headerNav a').on('click', function() {
    $('.navToggle').removeClass('closeNav');
    $('.site-header').removeClass('openNav');
    $('.headerNav').removeClass('openNav');
    $('body').removeClass('openNav');
  });
}

export function headerScrFunc() {
  console.log('スクロールで呼ばれます。');
}